import CmsHooks from "@shared/utils/register/CmsHooks";
import Layout from "@project/components/Layout";
import NotFound from "@project/components/NotFound";
import templates from "@project/templates/templates";
import routes from "@project/routes/routes";
import theme from "@project/theme";
import getDynamicComponent from "@project/utils/getDynamicComponent";
import { ContentLabelType, setDataStaticConsentFromFront, } from "@shared/components/cookies/config/definitions";
import { HOOKS } from "@shared/utils/register/definitions";
export default () => {
    CmsHooks.registerHook(HOOKS.PageSwitcher_404, NotFound);
    CmsHooks.registerHook(HOOKS.App_Layout, Layout);
    CmsHooks.registerHook(HOOKS.THEME, theme);
    CmsHooks.registerHook(HOOKS.TEMPLATES, templates);
    CmsHooks.registerHook(HOOKS.App_routes, routes);
    CmsHooks.registerHook(HOOKS.templatePropsUtils_getDynamicComponent, getDynamicComponent);
    CmsHooks.registerHook(HOOKS.TRACKING_ACTIVE, true);
    CmsHooks.registerHook(HOOKS.CONSENT_CONFIG, setDataStaticConsentFromFront(true, ContentLabelType.MTM_CONSENT_REMOVED, ContentLabelType.ALLOW_THE_PROPER_FUNCTIONING_OF_THE_SITE, ContentLabelType.MTM_CONSENT_REMOVED, ContentLabelType.MATOMO_SESSID));
};
